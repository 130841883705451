import styled from 'styled-components';
import { theme } from '@teamfabric/copilot-ui';

export const StyledTitleItem = styled.div`
  ${theme.typography.body1.regular}
`;

export const StyledSubTitleItem = styled.div`
  ${theme.typography.body2.regular};
  color: ${theme.color.grey[700]};
`;
