import React from 'react';
import { FeatureFlagsProvider } from 'hooks/useFeatureFlags/context';
import 'lib/theme/fonts.css';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle, theme, CopilotUIProvider } from '@teamfabric/copilot-ui';
import AppRoutes from './AppRoutes.js';
import initiateStore from '../store';
import { Provider } from 'react-redux';
import { FEATURE_FLAGS } from 'hooks/useFeatureFlags/utils';
import GenericPageLoader from '../components/GenearicPageLoader';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { updateV2Client } from 'service/clientV2';
import { updateV3Client } from 'service/clientV3';

const OMS = () => {
  const store = initiateStore({});

  const { isFetching } = useFeatureFlag(FEATURE_FLAGS.MT_SERVICE);

  if (isFetching) {
    return <GenericPageLoader />;
  }

  updateV2Client();
  updateV3Client();

  return (
    <Provider store={store}>
      <FeatureFlagsProvider>
        <CopilotUIProvider>
          <ThemeProvider theme={{ theme }}>
            <React.Fragment>
              <GlobalStyle />
              <AppRoutes />
            </React.Fragment>
          </ThemeProvider>
        </CopilotUIProvider>
      </FeatureFlagsProvider>
    </Provider>
  );
};

export default OMS;
