import { Box, GridCol, GridRow, Skeleton } from '@teamfabric/copilot-ui';

export const ContentLoader = () => {
  return (
    <>
      {Array.from({ length: 10 }, (_, i) => (
        <Box as='div' margin={{ top: 4, bottom: 4 }} key={i}>
          <Skeleton height={'32px'} width={'100%'} borderRadius={'4px'} />
        </Box>
      ))}
    </>
  );
};

const GenericPageLoader = () => {
  return (
    <Box
      as='div'
      flex={{ flexDirection: 'column' }}
      margin={{ top: 7 }}
      dataTestid='generic-page-loader'
    >
      <GridRow>
        <GridCol sm={6}>
          <Skeleton height={'32px'} width={'240px'} borderRadius={4} />
        </GridCol>
        <GridCol sm={6} justifySelf='flex-end'>
          <Skeleton height={'32px'} width={'120px'} borderRadius={4} />
        </GridCol>
      </GridRow>
      <Box as='div' margin={{ top: 4 }}>
        <GridRow>
          <Skeleton height={'32px'} width={'300px'} borderRadius={44} />{' '}
          &nbsp;&nbsp;
          <Skeleton height={'32px'} width={'32px'} borderRadius={16} />
        </GridRow>
      </Box>
      <Box as='div' margin={{ top: 8, left: 6, right: 6 }}>
        <ContentLoader />
      </Box>
    </Box>
  );
};

export default GenericPageLoader;
